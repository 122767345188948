import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Grid, Card, CardContent, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import img1 from '../../assets/images/img1.jpg';
import arrow from '../../assets/images/arrow.svg';
import Link from '@mui/material/Link';
//import DesignImg from '../../assets/images/DesignServiceImage.jpg'
import FDImg from '../../assets/images/FDImage.jpg';
import MaterialImg from "../../assets/images/MaterialImg.jpg";
import ECImage from "../../assets/images/EcImage.png";
//Get Images by const variable
import fastbaloonImg from '../../../assets/images/fast-baloon.png';
import CommonList from './CommonList';
import { common } from '@mui/material/colors';
import ReactPlayer from 'react-player';
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Fade from '@mui/material/Fade';
import { CONTACT_US_PAGE_CONTENT_URL } from '../../../common/APi/apiurl';
import Linkify from 'react-linkify';
import { HashLink } from 'react-router-hash-link';
import { useLocation, useNavigate } from 'react-router-dom';

const CommonRepetativeSectionsBanner = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPage = location.pathname;
  const { data, banner } = props;

  const [open, setOpen] = React.useState(false);
  const onCloseModal = () => setOpen(false);
  const [shown, setShown] = React.useState('');
  const [spanishTitle, setSpanishTitle] = React.useState('');
  const [buttonText, setButtonText] = React.useState('');
  const [currentVideoUrl, setCurrentVideoUrl] = React.useState('');
  const [currentUrl, setCurrentUrl] = React.useState('');
  const [activeSection, setActiveSection] = useState('');
  const [ourValueClassName, setOurValueClassName] = useState<string>("");

  useEffect(() => {
    const lowercasePathname = location.pathname.toLowerCase();

    let targetElementId = '';

    if (lowercasePathname === '/internships') {
      targetElementId = '#section323';
    } else if (lowercasePathname === '/apprenticeships') {
      targetElementId = '#section664';
    } else if (lowercasePathname === '/superate') {
      targetElementId = '#section710';
    }

    if (targetElementId) {
      setTimeout(() => {
        const anchor = document.createElement('a');
        anchor.href = targetElementId;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }, 1000);
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, ['/WhoWeAre']);

  const combinedItems = [...data, ...banner];
  const imageContentSections = [...data];
  const bannerSections = [...banner];
  const sortedItems = combinedItems.sort((a, b) => {
    const order =["728","729","730", "731", "138","733","139",'140','141','142','143','144'];
    return order.indexOf(a.nid) - order.indexOf(b.nid);
  });
  return (
    <>
      {/* Map over sortedItems data */}

      {sortedItems.map((commonData: any, index: any) => {
        let number = index;
        let transparantImage = commonData.field_bg_image_url;

        const handleDialogOpenTwo = (e: any, videoUrl: string) => {
          if (e.target.id === commonData.nid) {
            setCurrentVideoUrl(videoUrl);
            setOpen(true);
          }
        };

        function decodeEntity(inputStr: any) {
          var textarea = document.createElement("textarea");
          textarea.innerHTML = inputStr;
          return textarea.value;
        }

        const padding_bottom = {
          paddingBottom: commonData.field_center_bottom_padding + '!important'
        };

        const padding_left = {
          paddingLeft: commonData.field_pd_image_left + '!important'
        };

        const padding_right = {
          paddingRight: commonData.field_pd_image_right + '!important'
        };

        const padding_top = {
          paddingTop: commonData.field_center_top_padding + '!important'
        };

        var list_1 = commonData?.field_description2?.split("\r\n");
        var field1 = commonData?.field_description?.replace("&gt;", ">");
        var field2 = commonData?.field_description3?.replace("&gt;", ">");
        var bulletsColor = { "--bullet-bg-color": commonData.field_bullets_fgcolor } as React.CSSProperties;

        const field_description = decodeEntity(field1);

        const button_url = decodeEntity(commonData.field_button_url);

        var checkButtonRedirect = button_url.includes("https") ? button_url : button_url;


        if (commonData.nid === "729" || commonData.nid === "733") {
          return (
            <Box key={index} className='get-in-touch-expert' pt={6} pb={6} style={{ background: commonData.field_bg_color }}>
              <Grid container>
                <Grid item xs={12} xl={6} >
                  <Typography variant="h2" fontWeight="500" color="#ffffff" className='section-heading-expert' sx={{ paddingTop: "26px" }} >
                    {commonData.title}
                  </Typography>

                </Grid>
                <Grid item xs={12} xl={6} display="flex" justifyContent={{ xs: "center", xl: "flex-end" }}>

                  {commonData.field_button_text && (
                    <Button
                      component={RouterLink}
                      to={commonData.field_button_url}
                      variant="contained"
                      className='btn-orange'
                    >
                      {commonData.field_button_text}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          )
        }
        else {
          return (
            <Box className='section' style={{ background: commonData.field_bg_color }} id={"section" + (commonData.nid)} key={index}>
              <Grid container spacing={6} sx={{ mt: '0 !important' }} className={number % 2 !== 0 ? "revers" : ""}>
                <Grid item xs={12} lg={6} display='flex' justifyContent='center' flexDirection='column'>
                  <Box className="text-section" pb={{ xs: '20px', md: '64px' }} sx={{
                    pl: commonData.field_pd_left !== "" ? commonData.field_pd_left : "",
                    pr: commonData.field_pd_right !== "" ? commonData.field_pd_right : ""
                  }} >
                    {commonData.title !== "" && commonData.title !== "QUADRA™" &&
                      <Typography variant="h1" mb="12px" fontWeight="500" maxWidth={{ xs: '100%', lg: '691px' }}
                        color={{ color: commonData.field_title_fgcolor }} className='section-heading' style={bulletsColor}>
                        {commonData.title.split("amp;")}<br />
                        <span style={{ fontSize: '18px', display: 'inline-block', verticalAlign: 'top' }}>{commonData.field_title1}</span>
                      </Typography>
                    }


                    <Box maxWidth={{ xs: '100%', lg: '655px' }} >
                      {field1 !== "" &&
                        <Typography mb="20px" fontSize='25px' color={{ color: commonData.field_title_fgcolor }}>
                          <div dangerouslySetInnerHTML={{ __html: field_description }} />
                        </Typography>
                      }

                      {
                        <Typography mb="20px" fontSize='25px'>
                          <div dangerouslySetInnerHTML={{ __html: commonData.body }} />
                        </Typography>
                      }

                    </Box>

                    {commonData.field_button_text !== "" &&
                      commonData.field_button_type === 'video' &&
                      <Box mt={4}>
                        <Button
                          variant="contained"
                          id={commonData.nid}
                          onClick={(e) => handleDialogOpenTwo(e, commonData.field_video)}
                          className={commonData.field_button_color === "#29A7DF" ? 'btn-blue' :
                            commonData.field_button_color === "#FAFAFA" ? 'btn-white' : ''}
                          sx={{ px: '24px' }}
                        >
                          {commonData.field_button_type === 'video' ? commonData.field_button_text.replace("&#039;", "'") : 'N'}
                        </Button>
                      </Box>
                    }

                    {commonData.field_button_type === 'video' &&
                      <Box className='video-modal'>
                        <Modal open={open} onClose={onCloseModal} modalId={commonData.nid}>
                          <ReactPlayer url={currentVideoUrl} width="100%" height="100%" />
                        </Modal>
                      </Box>
                    }

                    {commonData.field_button_text !== "" &&
                      commonData.field_button_text !== "READ IN ENGLISH" &&
                      commonData.field_button_text !== "LEER EN ESPAÑOL" &&
                      commonData.field_button_text !== "COMPARE QUADRA™ TO OTHER BRANDS" &&
                      commonData.field_button_type !== 'video' &&

                      <Box mt={4}>
                        <Button
                          variant="contained"
                          id={commonData.nid}
                          target={commonData.field_button_url.includes("https") ? "_blank" : ''}
                          href={checkButtonRedirect}
                          className={commonData.field_button_color === "#29A7DF" ? 'btn-blue' :
                            commonData.field_button_color === "#FAFAFA" ? 'btn-white' : ''}
                          sx={{ px: '24px' }}
                        >
                          {commonData.field_button_type === 'pdf' || commonData.field_button_type === 'redirect' ?
                            commonData.field_button_text.split("amp;") : 'TO DO'}
                        </Button>
                      </Box>
                    }
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6} className="img-wrapper">
                  {/* Render image if no video is provided */}
                  {commonData.field_image_url_1 && !commonData.field_video && (
                    <Box
                      className="w-100 h-100"
                      display="flex"
                      pt={padding_top}
                      pb={padding_bottom}
                      pl={padding_left}
                      pr={padding_right}
                      justifyContent={commonData.field_justify_content || 'center'}
                      alignItems={commonData.field_align_items || 'center'}
                    >
                      <img src={commonData.field_image_url_1} alt="" className="img-responsive" />
                    </Box>
                  )}

                  {/* Render video if both video and image are provided */}
                  {commonData.field_video && commonData.field_image_url_1 && (
                    <Box
                      className="w-100 h-100"
                      display="flex"
                      justifyContent={commonData.field_justify_content || 'center'}
                      alignItems={commonData.field_align_items || 'center'}
                    >
                      <Box
                        className="video-wrapper"
                        pt={padding_top}
                        pb={padding_bottom}
                        pl={padding_left}
                        pr={padding_right}
                      >
                        <ReactPlayer url={commonData.field_video} width="100%" height="100%" />
                      </Box>
                    </Box>
                  )}
                </Grid>


              </Grid>
            </Box>
          );
        }
      })}
    </>
  );




};

export default CommonRepetativeSectionsBanner;
