import React, { useState, useEffect } from 'react'
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';


//API 
import { COMPLEX_EXTRUSIONS_RFQ_PAGE_URL} from '../common/APi/apiurl'

// Util
import {formatComplexRFQHeader, formatComplexRFQInfusion, formatComplexRFQAddress, formatComplexRFQThankYou} from '../common/utils/utils';

import { useLocation } from 'react-router-dom';
import RFQHeadSection from './sections/ComplexExtrusionRFQ/RFQHeadSection';
import RFQInfusionSection from './sections/ComplexExtrusionRFQ/RFQInfusionSection';
import RFQFooterSection from './sections/ComplexExtrusionRFQ/RFQFooterSection';
import { ComplexExtrusionsRFQResponse } from '../Service/ComplexExtrusionRFQ';


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '30px',
    },

    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 0,
        position: 'relative',
        backgroundColor: '#ffffff',
        fontSize: 16,
        padding: '11px 14px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1);',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    },
}));


const ComplexExtrusionsRFQ = () => {
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(true)
    const [pageContent, setPageContent] = useState<null | any>(null);
    const [LeadHeaderDetail, setLeadHeaderData] = useState<null | any>(null);
    const [LeadInfusionDetail, setLeadInfusionData] = useState<null | any>(null);
    const [LeadData, setLeadData] = useState<null | any>(null);
    const [LeadAddressDetail, setLeadAddressData] = useState<null | any>(null);
    const [LeadGenData, setLeadGenData] = useState<null | any>(null);
    const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(false)


    const handelSuccessBanner = (flag: boolean) => {
        setShowSuccessBanner(flag)
    }
    const fetchResponse = async () => {
        const response = await ComplexExtrusionsRFQResponse(COMPLEX_EXTRUSIONS_RFQ_PAGE_URL);
        setPageContent(response)
        setLoading(false);
    };

    const formatData = () => {
        let newsData;
        let titlesection: any;
        let LeadHeaderData;
        let LeadInfusionData;
        let LeadData;
        let LeadAddressData;
        let LeadGenData;
        if (pageContent) {
            console.log("page content in complex extrusions RFQ page:", pageContent);

            LeadHeaderData = formatComplexRFQHeader(pageContent);
            LeadInfusionData = formatComplexRFQInfusion(pageContent);
            LeadAddressData = formatComplexRFQAddress(pageContent);
            LeadGenData = formatComplexRFQThankYou(pageContent);
            // LeadGenData = formatContactUs(pageContent);
            setLeadInfusionData(LeadInfusionData);
            setLeadHeaderData(LeadHeaderData);
            setLeadData(LeadData);
            setLeadAddressData(LeadAddressData);
            setLeadGenData(LeadGenData);
        }
    }
    useEffect(() => {
        fetchResponse();
    }, []);
    useEffect(() => {
        formatData();
    }, [pageContent])

      useEffect(() => {
      window.scrollTo(0, 0)
    }, [location])
    return (
        <>
            {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
                <React.Fragment>
                    <RFQHeadSection data={LeadHeaderDetail} />
                    <RFQInfusionSection data={LeadInfusionDetail} handelSuccessBannerCallback={handelSuccessBanner} />
                    <RFQFooterSection data={LeadGenData} showBanner={showSuccessBanner} />
                </React.Fragment >
            }
        </>
    );
}
export default ComplexExtrusionsRFQ;