import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  CONTENT_URL, MICRO_CONTENT_URL, CLEANROOM_MOLDING_PAGE_CONTENT_URL, MEDICAL_POLYMER_URL, METALS_CONTENT_URL,
  ADVANCED_CATHETERS_CONTENT_URL, MANUFACTURING_CONTENT_URL, COMPLEX_EXTRUSION_PAGE_CONTENT_URL, LEADERSHIP_PAGE_CONTENT_URL,
  CAREERS_PAGE_CONTENT_URL, BALLOONS_PAGE_CONTENT_URL, WHO_WE_ARE_PAGE_CONTENT_URL, MARKETS_PAGE_CONTENT_URL,
  QUALITY_PAGE_CONTENT_URL, CERTIFICATIONS_PAGE_CONTENT_URL, LOCATIONS_PAGE_CONTENT_URL, IMAGEGUIDEDINTERVENSION_PAGE_CONTENT_URL, COMPONNET_DEVELOPEMENT,
  NEW_PRODUCT_DEVELOPEMNT, TECH_TRANSFER, MICRO_OVERMOLDING_URL, TINTUBE_MANUFACTURING, MDI_COATING, PHARMACEUTICAL_PACKAGING_PAGE_CONTENT_URL,
  AUTOMATION_PAGE_CONTENT_URL
} from '../common/APi/apiurl';
import {
  formatHeadingSection, formatRightSection1, formatCenterSection10, formatCommonRepitativeSectionType,
  formatBalloonCathTableType, formatExpertSection,
  formatSubmitRFQTodaySection,
  formatComplexExtrusionsRepitativeSection,
  formatComplexHaveQuestion
} from '../common/utils/utils';
import { pageResponse } from '../Service/ServiceDetail';
import BannerSection from './sections/ServiceDetailsLevel2/BannerSection';
import SectionRight1 from './sections/ServiceDetailsLevel2/SectionTextRight1';
import SectionCenterText10 from './sections/ServiceDetailsLevel2/SectionCenterText10';
import CommonRepetativeSections from './sections/ServiceDetailsLevel2/CommonRepetativeSections';
import CommonCreativeSolution from './CommonCreativeSolution'
import Header from './HeaderSections/Header';
import ExploreAboutBiomerics from './sections/ServiceDetailsLevel2/ExploreAboutBiomerics';
import TopSubMenuAboutUs from './sections/ServiceDetailsLevel2/TopSubMenuAboutUs'
import CommonTable from './sections/ServiceDetailsLevel2/TabelsSections/CommonTable';
import { MICRO_METAL_FINISHED_ASSEMBLY_URL, COMPLEX_EXTRUSIONSS_PAGE_URL } from '../common/APi/apiurl';
import SectionGetInExpert from './sections/ServiceDetailsLevel2/SectionGetInExpert';
import CommonRepetativeSectionsBanner from './sections/ServiceDetailsLevel2/CommonRepetativeSectionBanner'


const ComplexExtrusionsNew = () => {
  let pageURL;
  const pathName = useLocation();
  let currentPage = pathName.pathname;
  const location = useLocation().pathname;
  const [loading, setLoading] = useState<boolean>(true)
  const [pageContent, setContent] = useState<null | any>(null);
  const [HeadingSectionData, setHeadingSection] = useState<null | any>(null);
  const [SectionRightData1, setSectionRightData1] = useState<null | any>(null);
  const [centerSectionData10, setCenterSectionData10] = useState<null | any>(null);
  const [repitativeSectionTypeData, setRepitativeSectionTypeData] = useState<null | any>(null);
  const [TableSectionTypeData, setTableSectionTypeData] = useState<null | any>(null);
  const [expertSectionData, setExpertSection] = useState<null | any>(null);
  const [submitComplexExtrusionsRFQSection, setSubmitComplexExtrusionsRFQSection] = useState<null | any>(null);
  const [complexExtrusionsRepitativeSection, setComplexExtrusionsRepitativeSection] = useState<null | any>(null);

  const [haveAQuestionSection, setHaveAQuestionSection] = useState<null | any>(null);


  const [hash, setHash] = useState<string>(pathName.hash ? pathName.hash : "");


  const urlContentMappings = [
    { url: "/ComplexExtrusions", contentUrls: [COMPLEX_EXTRUSION_PAGE_CONTENT_URL, COMPLEX_EXTRUSIONSS_PAGE_URL] }
  ];

  console.log('urlContentMappings',urlContentMappings)
  // Map the URLs to lowercase for case-insensitive comparison
  const mappedURLs = urlContentMappings.map(item => item.url.toLowerCase());

  // Function to compare currentPage with mappedURLs
  function compareUrl(currentPage: string, mappedURLs: string[]): boolean[] {
    currentPage = currentPage.toLowerCase(); // Convert currentPage to lowercase

    // Check for matches between currentPage and the mapped URLs
    const matchArray = mappedURLs.map((pageName) => currentPage === pageName);

    return matchArray; // Return the array of booleans
  }

  const matchResults = compareUrl(currentPage, mappedURLs);

  // Check if there's any match
  const isSame = matchResults.some((match) => match);

  const fetchResponse = async () => {
    if (isSame) {
      // Find the index of the matched URL
      const matchedIndex = matchResults.findIndex((match) => match);
      const matchedUrlMapping = urlContentMappings[matchedIndex];

      // Extract the contentUrls (array) for the matched URL
      const contentUrls = matchedUrlMapping.contentUrls; // Ensure this is an array of URLs

      try {
        // Fetch content from all URLs (using Promise.all to handle multiple URLs)
        const responses = await Promise.all(contentUrls.map(url => pageResponse(url)));

        // Process the responses as needed (for example, set them to state)
        setContent(responses);

        console.log("Fetched responses:", responses);
      } catch (error) {
        console.error("Error fetching content:", error);
      }

      // Set loading to false after the fetch process is complete
      setLoading(false);
    } else {
      console.error("No matching URL found for currentPage:");
    }
  };

  const formatData = () => {
    let newsData;
    let titlesection: any;
    let HeadingSection;
    let SectionRightData1;
    let centerSectionData10;
    let repitativeSectionTypeData;
    let TableSectionTypeData;
    let expertSection;
    let submitComplexExtrusionsRFQSection;
    let complexExtrusionsRepitativeSection;
    let haveAQuestionSection;


    if (pageContent) {
      HeadingSection = formatHeadingSection(pageContent[0]);
      SectionRightData1 = formatRightSection1(pageContent[0]);
      centerSectionData10 = formatCenterSection10(pageContent[0]);
      repitativeSectionTypeData = formatCommonRepitativeSectionType(pageContent[0]);
      expertSection = formatExpertSection(pageContent[0]);
      submitComplexExtrusionsRFQSection = formatSubmitRFQTodaySection(pageContent[0]);
      complexExtrusionsRepitativeSection = formatComplexExtrusionsRepitativeSection(pageContent[1]);
      TableSectionTypeData = formatBalloonCathTableType(pageContent[1]);
      haveAQuestionSection= formatComplexHaveQuestion(pageContent[1]);

      const combinedRepitativeSectionData = [
        ...repitativeSectionTypeData,
        ...complexExtrusionsRepitativeSection,
      ];
      setHeadingSection(HeadingSection);
      setSectionRightData1(SectionRightData1);
      setTableSectionTypeData(TableSectionTypeData);
      setRepitativeSectionTypeData(repitativeSectionTypeData);
      setSubmitComplexExtrusionsRFQSection(submitComplexExtrusionsRFQSection);
      setComplexExtrusionsRepitativeSection(combinedRepitativeSectionData);
      setHaveAQuestionSection(haveAQuestionSection);
    }
  }

  setTimeout(() => {
    window.addEventListener("scroll", () => {
      document.querySelector('.headerPadding')?.classList.remove('headerPadding');
    })
  }, 1500)


  useEffect(() => {
    const document: Document = window.document || {} as Document;
    if (pathName.hash) {
      if (document?.querySelector(pathName.hash)) {
        setTimeout((e: any) => {
          document?.querySelector(pathName.hash)?.classList.add('headerPadding')
          document?.querySelector(pathName.hash)?.scrollIntoView({
            block: "nearest",
            inline: "start"
          });
        }, 700)

      }

    }
    return () => {
    }
  }, [pathName, loading]);

  useEffect(() => {
    fetchResponse();
  }, [location])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    formatData();

  }, [pageContent])

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener("scroll", () => {
        document.querySelector('.headerPadding')?.classList.remove('headerPadding');
      })
    }, 3000)
    return () => {
      window.removeEventListener("scroll", () => {
      })
    }
  }, [])

  useEffect(() => {
    const handleHashScroll = () => {
      if (pathName.hash) {
        const targetElement = document.querySelector(pathName.hash);
        if (targetElement) {
          targetElement.classList.add('headerPadding');
          targetElement.scrollIntoView({ block: 'start', inline: 'start' });
        }
      }
    };

    // Scroll to the specified section when the component mounts
    handleHashScroll();

    // Add a scroll event listener
    const handleScroll = () => {
      const headerPaddingElement = document.querySelector('.headerPadding');
      if (headerPaddingElement) {
        headerPaddingElement.classList.remove('headerPadding');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      const headerPaddingElement = document.querySelector('.headerPadding');
      if (headerPaddingElement) {
        headerPaddingElement.classList.remove('headerPadding');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleHashScroll = () => {
      if (pathName.hash) {
        const targetElement = document.querySelector(pathName.hash);
        if (targetElement) {
          targetElement.classList.add('headerPadding');
          targetElement.scrollIntoView({ block: 'start', inline: 'start' });
        }
      }
    };

    handleHashScroll();

    window.addEventListener('hashchange', handleHashScroll);

    return () => {
      window.removeEventListener('hashchange', handleHashScroll);
    };
  }, [pathName]);



  useEffect(() => {
    const lowercasePathname = pathName.pathname.toLowerCase();
    const lowercaseHash = pathName.hash.toLowerCase();
    if (lowercaseHash) {
      setTimeout(() => {
        const anchor = document.createElement('a');
        anchor.href = lowercaseHash;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }, 1000);
    }
  }, [pathName]);

  console.log('complexExtrusionsRepitativeSection',complexExtrusionsRepitativeSection)

  return (
    <>
      {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
        <React.Fragment>

          <BannerSection data={HeadingSectionData} />

          <SectionRight1 data={SectionRightData1} />

          {submitComplexExtrusionsRFQSection && complexExtrusionsRepitativeSection && submitComplexExtrusionsRFQSection.length > 0 && (
            <CommonRepetativeSectionsBanner
              data={complexExtrusionsRepitativeSection}
              banner={submitComplexExtrusionsRFQSection}
            />
          )
          }

          {TableSectionTypeData !== '' ? <CommonTable data={TableSectionTypeData} /> : ''}

          {haveAQuestionSection == "" ? <ExploreAboutBiomerics /> : <SectionCenterText10 data={haveAQuestionSection} />}

          <CommonCreativeSolution />
        </React.Fragment>
      }
    </>
  )
}

export default ComplexExtrusionsNew
