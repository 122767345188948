import React, { FC, useState, useCallback, useEffect, useRef } from 'react'
import { Box, Container, Grid, InputAdornment, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import e2eImage from '../../../assets/images/img4.jpg';
import Link from '@mui/material/Link';
import iconCall from '../../../assets/images/icons/call.svg';
import iconPin from '../../../assets/images/icons/pin.svg';
import iconEmail from '../../../assets/images/icons/email.svg';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import ContactAddress from '../ContactSection/ContactAddress';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import MuiPhoneNumber from 'material-ui-phone-number';
import { GOOGLE_CAPTCHA_SITE_KEY, PROD_GOOGLE_CAPTCHA_SITE_KEY, DEV_GOOGLE_CAPTCHA_SITE_KEY } from '../../../common/Constants';
import { fetchApplicationDropdownValue, fetchMaterialNeedsDropdownValue, postFormDataForComplexExtrusionsRFQ } from '../../../Service/ComplexExtrusionRFQ';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '30px',
    },

    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 0,
        position: 'relative',
        backgroundColor: '#ffffff',
        fontSize: 16,
        padding: '11px 14px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1);',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    },
}));

interface userContactInformation {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    IDAllowableTolerances: string;
    ODAllowableTolerances: string;
    WallThicknessAllowableTolerances: string;
    CutLength: string;
    NumberofPartstoQuote: string;
    Application: string;
    MaterialNeeds: string;
    PackagingRequirements: string;
    UploadFile: string;
}


export interface ErrorMessage {
    firstName: string;
    lastName: string;
    company: string;
    email: string;
    IDAllowableTolerances: string;
    ODAllowableTolerances: string;
    WallThicknessAllowableTolerances: string;
    CutLength: string;
    NumberofPartstoQuote: string;
    Application: string;
    MaterialNeeds: string;
    PackagingRequirements: string;
    UploadFile: any;
}

export interface Phonenum {
    phone: string;
}
const phone_value: Phonenum = {
    phone: ''
};


const initial_value: userContactInformation = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    IDAllowableTolerances: '',
    ODAllowableTolerances: '',
    WallThicknessAllowableTolerances: '',
    CutLength: '',
    NumberofPartstoQuote: '',
    PackagingRequirements: '',
    Application: '',
    MaterialNeeds: '',
    UploadFile: ''
}

interface ApiResponseDropdown {
    body: string[],
    loading: boolean
}

const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;


const RFQInfusionSection = (props: any) => {
    const { data } = props;

    const [contactInputField, setContactFormField] = useState<userContactInformation>(initial_value);
    const [contactNumberField, setContactNumberField] = useState<Phonenum>(phone_value);
    const [loading, setLoading] = useState<boolean>(true);
    const [phone, setPhone] = useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [selectedFileName, setSelectedFileName] = useState('');
    const [verified, setVerified] = useState(false);
    const myRef = useRef(null);

    const scrollToRef = (ref: any) => window.scrollTo(0, 1200);

    const [applicationApiResponse, setApplicationApiResponse] = useState<ApiResponseDropdown>({
        loading: true,
        body: []
    })

    const [materialNeedsApiResponse, setMaterialNeedsApiResponse] = useState<ApiResponseDropdown>({
        loading: true,
        body: []
    });
    //const [disable, setDisable]=useState<boolean>(true);
    //const [area]
    const [error, setError] = useState<ErrorMessage>({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        IDAllowableTolerances: '',
        ODAllowableTolerances: '',
        WallThicknessAllowableTolerances: '',
        CutLength: '',
        NumberofPartstoQuote: '',
        PackagingRequirements: '',
        Application: '',
        MaterialNeeds: '',
        UploadFile: ''
    });

    const clearInputField = () => {
        setContactFormField(initial_value);
    }

    useEffect(() => {

        window.scroll(0, 0)
        const response = fetchApplicationDropdownValue();
        response.then((res: any): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            setApplicationApiResponse({ loading: false, body: dummyString.split(",") })
        }).catch((err: any) => {
            console.log('e', err)
        })
    }, [])


    useEffect(() => {

        window.scroll(0, 0)
        const response = fetchMaterialNeedsDropdownValue();
        response.then((res: any): void => {
            setLoading(false);
            const dummyString = res.body.replace(/(^"|"$)/g, '')
            setMaterialNeedsApiResponse({ loading: false, body: dummyString.split(",") })
        }).catch((err: any) => {
            console.log('e', err)
        })
    }, [])

    const clearPhoneField = () => {

        setContactNumberField(phone_value);
    }

    const handelInputChange = (e: any) => {
        const { name, value, id } = e.target;

        const re = /^\S*$/;
        let errors: ErrorMessage = error;
        setContactFormField({ ...contactInputField, [name]: value, [id]: value })
        if (name === 'firstName') {

            if (!value) {
                setError({ ...error, [name]: "Please add valid name" })
            } else {
                setError({ ...error, [name]: "" })
            }
            if (value.length < 4) {
                setError({ ...error, [name]: "Please add valid name" })

            } else {
                setError({ ...error, [name]: "" })
            }
        } else if (name === 'lastName') {
            if (!value) {
                setError({ ...error, [name]: "Please add valid Last name" })
            } else {
                setError({ ...error, [name]: "" })
            }
            if (value.length < 4) {
                setError({ ...error, [name]: "Please add valid last name" })
            }

        } else if (name === 'email') {
            if (!value) {

                setError({ ...error, [name]: "Please enter your email Address." })

            } else {
                setError({ ...error, [name]: "Please fill email field" })
            }

            if (typeof value !== "undefined") {
                if (!value) {
                    // isValid = false;
                    // errors["email"] = "Please enter valid email address.";
                    setError({ ...error, [name]: "Please enter valid email address." })
                } else {
                    setError({ ...error, [name]: "" })
                }
            }
        }

        else if (name === "Application") {
            if (!value) {
                setError({ ...error, [name]: "Please select any one" })
            } else {
                setError({ ...error, [name]: "" })
            }
        }

        else if (name === "MaterialNeeds") {
            if (!value) {
                setError({ ...error, [name]: "Please select any one" })
            } else {
                setError({ ...error, [name]: "" })
            }
        }
    }

    const handleFileUpload = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFileName(file.name);
            uploadFile(file);
        }
    };

    const uploadFile = async (file: any) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            // Replace this URL with your upload endpoint
            const response = await fetch('', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                console.log('File uploaded successfully');
            } else {
                console.error('File upload failed');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handelSubmit = (e: React.SyntheticEvent) => {
        // window.scrollTo(0,1200);
        scrollToRef(myRef);
        let screen_width = window.screen.width;
        if (screen_width < 768) {
            window.scrollTo(0, 2000);
        }

        e.preventDefault();

        const contactPayload = {
            "firstname": contactInputField.firstName,
            "lastname": contactInputField.lastName,
            "company": contactInputField.company,
            "email": contactInputField.email,
            "ID_Allowable_Tolerances__c": contactInputField.IDAllowableTolerances,
            "OD_Allowable_Tolerances__c": contactInputField.ODAllowableTolerances,
            "Wall_Thickness_Allowable_Tolerances__c": contactInputField.WallThicknessAllowableTolerances,
            "Cut_Length__c": contactInputField.CutLength,
            "Number_of_Parts_to_Quote__c": contactInputField.NumberofPartstoQuote,
            "Application__c": contactInputField.Application,
            "Material_Needs__c": contactInputField.MaterialNeeds,
            "Packaging_Requirements__c": contactInputField.PackagingRequirements,
            // "Uploaded_File": contactInputField.UploadFile
        }

        console.log("contactPayload......", contactPayload);
        const response = postFormDataForComplexExtrusionsRFQ(JSON.stringify(contactPayload));
        console.log("response in Complex Extrusions RFQ:", response);

        response.then((res: any) => {
            props.handelSuccessBannerCallback(true);
            clearPhoneField();
            clearInputField();
            setPhone("");
        }).catch((err: any) => {
            new Error(err)
        })
    }
    return (
        <Box className="connecting-frm" sx={{ paddingBottom: "90px" }}>
            <Grid container spacing={9}>
                <Grid item xs={12} lg={6}>
                    <Box pl={{ xs: '20px', lg: '100px', xl: '176px' }} pr={{ xs: '20px', md: '0' }} pt={7} pb={4}>
                        <Typography variant="h1" fontSize='36px' color='#4A4948' fontWeight='500'
                            className='content-heading'>{data[0].title.replace("&#039;", "'")}</Typography>
                        <Typography fontSize='25px' color='#131313'>
                            {data[0].field_description.replace("&#039;", "'")}
                        </Typography>

                        <Typography fontSize='18px' color='#131313'>
                            {data[0].field_description2.replace("&#039;", "'")}
                        </Typography>
                        <Box className='contact-details' mt={9}>
                            <Typography fontSize='18px' variant='h5' color='#29A7DF' mb={3}>
                                Biomerics Corporate Headquarters
                            </Typography>
                            <Typography fontSize='18px' color='#131313'><a href="tel:1 888.874.7787"><img src={iconCall} alt="" /></a>
                                <Link href="tel:1 888.874.7787" underline="none" color="#4A4948">+1 888-874-7787</Link></Typography>
                            <Typography fontSize='18px' color='#131313'><a href="tel:1 801.355.2705"><img src={iconCall} alt="" /></a>
                                <Link href="tel:1 801-355-2705" underline="none" color="#4A4948">+1 801-355-2705</Link></Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href='mailto:info@biomerics.com' target="_blank" rel="noreferrer"><img src={iconEmail} alt="" /></a>
                                <Link href="mailto:info@biomerics.com" target="_blank" color="#4A4948">info@biomerics.com</Link></Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href="https://g.page/biomerics?share" target="_blank" rel="noreferrer">
                                    <img src={iconPin} alt="" />
                                </a>
                                6030 W. Harold Gatty Dr., Salt Lake City, UT 84116, USA</Typography>
                            <Typography fontSize='18px' color='#131313'>
                                <a href="/Locations"><img src={iconPin} alt="" /></a>
                                <Link href="/Locations" color='#29A7DF'>View other locations</Link></Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Box pr={{ xs: '20px', lg: '60px', xl: '140px' }} pl={{ xs: '20px', lg: '65px' }} py={7} pb={5} className='contact-frm'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='First Name*' name='firstName' onChange={handelInputChange} value={contactInputField.firstName} />
                                    {error.firstName.length > 0 ? <FormHelperText>{error.firstName}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Last Name*' name='lastName' onChange={handelInputChange} value={contactInputField.lastName} />
                                    {error.lastName.length > 0 ? <FormHelperText>{error.lastName}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Company*' name='company' onChange={handelInputChange} value={contactInputField.company} />
                                    {error.company.length > 0 ? <FormHelperText>{error.company}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl variant="standard" className='w-100' sx={{ mb: '6px' }}>
                                    <BootstrapInput placeholder='Email*' name='email' onChange={handelInputChange} value={contactInputField.email} />
                                    {error.email.length > 0 ? <FormHelperText>{error.email}</FormHelperText> : ''}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} container alignItems="center" spacing={2}>
                                {/* Label on the left */}
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal', // Allow wrapping for multi-line text
                                        }}
                                    >
                                        ID & Allowable Tolerances (Range)
                                    </InputLabel>
                                </Grid>

                                {/* Input field on the right */}
                                <Grid item xs={8}>
                                    <FormControl variant="standard" className="w-100">
                                        <BootstrapInput
                                            multiline
                                            placeholder="ID & Allowable Tolerances"
                                            name="IDAllowableTolerances"
                                            onChange={handelInputChange}
                                            value={contactInputField.IDAllowableTolerances}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {/* OD & Allowable Tolerances (Range) */}
                            <Grid item xs={12} container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        OD & Allowable Tolerances (Range)
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl variant="standard" className="w-100">
                                        <BootstrapInput
                                            multiline
                                            placeholder="OD & Allowable Tolerances"
                                            name="ODAllowableTolerances"
                                            onChange={handelInputChange}
                                            value={contactInputField.ODAllowableTolerances}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {/* Wall Thickness & Allowable Tolerances (Range) */}
                            <Grid item xs={12} container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        Wall Thickness & Allowable Tolerances (Range)
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl variant="standard" className="w-100">
                                        <BootstrapInput
                                            multiline
                                            placeholder="Wall Thickness & Allowable Tolerances"
                                            name="WallThicknessAllowableTolerances"
                                            onChange={handelInputChange}
                                            value={contactInputField.WallThicknessAllowableTolerances}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {/* Cut Length */}
                            <Grid item xs={12} container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        Cut Length
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl variant="standard" className="w-100">
                                        <BootstrapInput
                                            multiline
                                            placeholder="Cut Length"
                                            name="CutLength"
                                            onChange={handelInputChange}
                                            value={contactInputField.CutLength}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {/* Number of Parts to Quote */}
                            <Grid item xs={12} container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        Number of Parts to Quote
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl variant="standard" className="w-100">
                                        <BootstrapInput
                                            multiline
                                            placeholder="Number of Parts to Quote"
                                            name="NumberofPartstoQuote"
                                            onChange={handelInputChange}
                                            value={contactInputField.NumberofPartstoQuote}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {/* Application */}
                            <Grid item xs={12} container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        Application*
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        sx={{
                                            width: '100%',
                                            borderRadius: '0',
                                            backgroundColor: '#ffffff',
                                            fontSize: '16px',
                                            boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)',
                                            border: 'none',
                                        }}
                                        className="w-100 frm-select"
                                        value={contactInputField.Application}
                                        onChange={handelInputChange}
                                        name="Application"
                                        displayEmpty
                                        renderValue={
                                            contactInputField.Application !== ""
                                                ? undefined
                                                : () => <Box className="select-placeholder">Vascular Access</Box>
                                        }
                                        input={<OutlinedInput id="bootstrap-input" />}
                                    >
                                        {applicationApiResponse.body?.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {error.Application.length > 0 && <FormHelperText>{error.Application}</FormHelperText>}
                                </Grid>
                            </Grid>

                            {/* Material Needs */}
                            <Grid item xs={12} container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="bootstrap-input"
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        Material Needs*
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        sx={{
                                            width: '100%',
                                            borderRadius: '0',
                                            backgroundColor: '#ffffff',
                                            fontSize: '16px',
                                            boxShadow: '0px 3px 6px rgb(0 0 0 / 10%)',
                                            border: 'none',
                                        }}
                                        className="w-100 frm-select"
                                        value={contactInputField.MaterialNeeds}
                                        onChange={handelInputChange}
                                        name="MaterialNeeds"
                                        displayEmpty
                                        renderValue={
                                            contactInputField.MaterialNeeds !== ""
                                                ? undefined
                                                : () => <Box className="select-placeholder">Rigid Vinyl (RPVC)</Box>
                                        }
                                        input={<OutlinedInput id="bootstrap-input" />}
                                    >
                                        {materialNeedsApiResponse.body?.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {error.MaterialNeeds.length > 0 && <FormHelperText>{error.MaterialNeeds}</FormHelperText>}
                                </Grid>
                            </Grid>


                            {/* <Grid item xs={12}>
                                <FormControl variant="standard" className='w-100'>
                                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                        ID & Allowable Tolerances(Range)
                                    </InputLabel>
                                    <BootstrapInput multiline={true} placeholder='' name='IDAllowableTolerances' onChange={handelInputChange}
                                        value={contactInputField.IDAllowableTolerances} />
                                </FormControl>
                            </Grid> */}
                            {/* 

                            <Grid item xs={12}>
                                <FormControl variant="standard" className='w-100'>
                                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                        OD & Allowable Tolerances(Range)
                                    </InputLabel>
                                    <BootstrapInput multiline={true} placeholder='' name='ODAllowableTolerances' onChange={handelInputChange}
                                        value={contactInputField.ODAllowableTolerances} />
                                </FormControl>
                            </Grid> */}
                            {/* 
                            <Grid item xs={12}>
                                <FormControl variant="standard" className='w-100'>
                                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                        Wall Thickness & Allowable Tolerances(Range)
                                    </InputLabel>
                                    <BootstrapInput multiline={true} placeholder='' name='WallThicknessAllowableTolerances' onChange={handelInputChange}
                                        value={contactInputField.WallThicknessAllowableTolerances} />
                                </FormControl>
                            </Grid> */}

                            {/* 
                            <Grid item xs={12}>
                                <FormControl variant="standard" className='w-100'>
                                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                        Cut Length
                                    </InputLabel>
                                    <BootstrapInput multiline={true} placeholder='' name='CutLength' onChange={handelInputChange}
                                        value={contactInputField.CutLength} />
                                </FormControl>
                            </Grid> */}

                            {/* <Grid item xs={12}>
                                <FormControl variant="standard" className='w-100'>
                                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#000 !important', transform: 'none' }}>
                                        Number of Parts to Quote
                                    </InputLabel>
                                    <BootstrapInput multiline={true} placeholder='' name='NumberofPartstoQuote' onChange={handelInputChange}
                                        value={contactInputField.NumberofPartstoQuote} />
                                </FormControl>
                            </Grid> */}

                            <Grid item xs={12}>
                                <FormControl variant="standard" fullWidth sx={{ mb: 0, mt: 2 }}>
                                    <InputLabel
                                        htmlFor="bootstrap-input"
                                        shrink={true}
                                        sx={{
                                            color: '#000 !important',
                                            transform: 'none',
                                            whiteSpace: 'normal',
                                            position: 'relative',
                                            bottom: 0,
                                            marginBottom: '0 !important',
                                        }}
                                    >
                                        What packaging requirements does your project have?
                                    </InputLabel>
                                    <BootstrapInput
                                        multiline={true}
                                        placeholder='Packaging requirements'
                                        name='PackagingRequirements'
                                        onChange={handelInputChange}
                                        value={contactInputField.PackagingRequirements}
                                        sx={{
                                            margin: '0 !important',
                                            paddingTop: '10px !important',
                                            paddingBottom: '0 !important',
                                        }}
                                    />
                                </FormControl>
                            </Grid> 

                            <Grid item xs={12}>
                                <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        sx={{ px: '24px' }}
                                        onClick={handelSubmit}
                                        disabled={
                                            !(contactInputField.firstName &&
                                                contactInputField.lastName &&
                                                contactInputField.company &&
                                                contactInputField.email)
                                        }
                                    >
                                        SUBMIT
                                    </Button>
                                </Box>
                            </Grid>


                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}

export default RFQInfusionSection;