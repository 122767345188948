import * as React from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

const RFQFooterSection = (props: any) => {
  const { data, showBanner } = props;
  const [open, setOpen] = React.useState(showBanner);

  console.log("props in RFQFooterSection......", props);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(showBanner);
  }, [showBanner]);

  return (
    <div>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        {showBanner && (
          <Alert
            severity="success"
            variant="filled"
            sx={{ 
              width: '60%', 
              backgroundColor: '#29a6de', 
              fontSize: '20px',
              color: '#ffffff',
              fontWeight: '500',
            }}
               className="text-italic"
          >
            {data[0]?.title}
          </Alert>
        )}
      </Snackbar>
      {/* <Box className='contact-img'>
        <img src={data[0].field_image_url_1} alt="" />
      </Box> */}
    </div>
  );
};

export default RFQFooterSection;
