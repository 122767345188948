import Api from "../../common/APi/api";
import axios from "axios";


export const ComplexExtrusionsRFQResponse = async (url: string) => {
  const response = await Api.get(url);
  return response.data;
}

//on submit
export const postFormDataForComplexExtrusionsRFQ = async (payloadBody: any) => {
  const res = await axios.post('https://zknc5ufwjg.execute-api.us-east-1.amazonaws.com/dev/v1/bio/complexExtrusionRfq',
    payloadBody, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  });

  return res.data;
}


export const fetchApplicationDropdownValue = async () => {
  const response = await axios.get('https://zknc5ufwjg.execute-api.us-east-1.amazonaws.com/dev/v1/bio/application');

  return response.data;
}

export const fetchMaterialNeedsDropdownValue = async () => {
  const response = await axios.get('https://zknc5ufwjg.execute-api.us-east-1.amazonaws.com/dev/v1/bio/materialNeeds');

  return response.data;
}
